.cover-component {
    background-size: cover;
    background-position: center;
    min-height: 120vh;
    padding: 80px 0;
    margin: 0;
}

.cover-component .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 60px;
}

.cover-component .cover-component-title {
    text-align: center;
    font-size: 50px;
    letter-spacing: 3px;
    margin: 0;
    animation-name: titleSlideIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: 100ms;
    opacity: 0;
    color: white;
    text-shadow: 2px 2px 2px black;
}

.cover-component .textbox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.54);
    padding: 80px 80px; 
    border-radius: 20px; 
}

.cover-component .textbox .responsive-logo {
    display: none;
}

@keyframes titleSlideIn {
    0% {
        opacity: 0;
        transform: translateX(-500px);
    }
    100% {
        opacity: 1;
        transform: translateX(0)
    }
}

.cover-component .cover-component-headline {
    text-align: center;
    font-size: 33px;
    letter-spacing: 2px;
    color: white;
    animation-name: headlineSlideIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: 500ms;
    opacity: 0;
    text-shadow: 2px 2px 2px black;

}

@keyframes headlineSlideIn {
    0% {
        opacity: 0;
        transform: translateX(500px);
    }
    100% {
        opacity: 1;
        transform: translateX(0)
    }
}

.cover-component-button {
    font-weight: bold;
    border-radius: 25px;
    justify-content: center;
    padding: 12px 40px;
    display: flex;
    font-size: 20px;
    background-color: rgb(255, 183, 0);
    color: black;
    border: none ;
    margin-top: 40px;
    letter-spacing: 2px;
    animation-name: buttonFadeIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: 800ms;
    opacity: 0;

}

@keyframes buttonFadeIn {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    100% {
        opacity: 1;
        transform: scale(1)
    }
}

.cover-component-button:hover {
    background-color: rgb(3, 188, 216);
    box-shadow: 1px 1px 40px 3px white;
    rotate: -2deg;
    transform: scale(1.1);
    transition: all 300ms;
    color: white;
    cursor: pointer;

}

@media screen and (max-width: 1024px) {

    .cover-component {
        background-size: cover;
        background-position: left;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .cover-component .container {
        padding: 30px;
    }

    .cover-component .cover-component-title {
        font-size: 22px;
        margin-top: 10px;
        margin-bottom: 20px ;
    }

    .cover-component .cover-component-headline {
        font-size: 18px;
    }

    .cover-component .textbox .responsive-logo {
        display: block;
        height: 200px;
        width: 150px;
    }

}

@media screen and (max-width: 768px) {

    .cover-component {
        background-size: cover;
        background-position: left;
    }

    .cover-component .cover-component-title {
        font-size: 22px;
        margin-top: 10px;
    }

    .cover-component .cover-component-headline {
        font-size: 18px;
    }

    .cover-component .textbox .responsive-logo {
        display: block;
        height: 150px;
        width: 100px;
    }

}
