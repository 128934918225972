.first-layer {
    padding: 150px;
    min-height: 80vh;
    background-size: cover;
    background-position: center;
    position: relative;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width:1024px) {
        padding: 0px;
        background-attachment: unset;

    }
    
    &:hover {
        .headline {
            background: rgba(56, 56, 56, 0.9);
            color: white;
        }
    }

    &::before {
        content: ''; //mert aminek nincs tartalma azt nem jeleníti meg a böngésző
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        background: rgba(rgb(156, 156, 156), .5);
        transition: all linear 100ms;
    }

    &:hover::before {
        background: rgba(white, .7);
    }
    
    * {
        z-index: 4;
        position: relative;
    }

    .headline {
        font-size: 60px;
        letter-spacing: 5px;
        background: rgb(255, 255, 255);
        border-radius: 20px;
        padding: 13px 20px;
        text-align: center;
        height: 50%;

        @media screen and (max-width:1024px) {
            font-size: 24px;
            margin-bottom: 30px;
            letter-spacing: 1px;
        }
    }

    .description {
        font-size: 35px;
        width: 75%;
        line-height: 45px;
        letter-spacing: 1px;
        font-weight: 500;
        text-align: justify;

        @media screen and (max-width:1024px) {
            font-size: 19px;
            line-height: 20px;

        }
    }
}

.second-layer {
    padding: 80px 100px;
    min-height: 30vh;
    background-size: cover;
    background-position: center;
    position: relative;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width:1024px) {
        padding: 36px;
        background-attachment: unset;
    }

    &:hover {
        .headline {
            background: rgba(56, 56, 56, 0.9);
            color: white;
        }
    }

    &::before {
        content: ''; //mert aminek nincs tartalma azt nem jeleníti meg a böngésző
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        background: rgba(rgb(0, 0, 0), .3);
        transition: all linear 100ms;
    }

    &:hover::before {
        background: rgba(white, .4);
    }
    
    * {
        z-index: 4;
        position: relative;
    }

    .headline {
        font-size: 60px;
        letter-spacing: 5px;
        background: rgb(255, 255, 255);
        border-radius: 20px;
        padding: 13px 20px;
        text-align: center;
        height: 50%;

        @media screen and (max-width:1024px) {
            font-size: 24px;
            margin-bottom: 30px;
            letter-spacing: 1px;
        }
    }

    .description {
        font-size: 35px;
        line-height: 45px;
        letter-spacing: 1px;
        font-weight: 500;
        text-align: justify;

        @media screen and (max-width:1024px) {
            font-size: 19px;
            line-height: 20px;
        }
    }
}

.third-layer {
    padding: 150px;
    min-height: 70vh;
    background-size: cover;
    background-position: center;
    position: relative;
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width:1024px) {
        padding: 0 49px;
        font-size: 30px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-attachment: unset;

    }

    &:hover {
        .headline {
            background: rgba(56, 56, 56, 0.9);
            color: white;
        }
    }

    &.reverse {
        flex-direction: row-reverse;
    }

    &::before {
        content: ''; //mert aminek nincs tartalma azt nem jeleníti meg a böngésző
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        background: rgba(rgb(156, 156, 156), .4);
        transition: all linear 100ms;
    }
    
    &:hover::before {
        background: rgba(white, .7);
    }

    * {
        z-index: 4;
        position: relative;
    }

    .headline {
        font-size: 60px;
        letter-spacing: 5px;
        background: rgb(255, 255, 255);
        border-radius: 20px;
        padding: 13px 20px;
        text-align: center;
        height: 50%;

        @media screen and (max-width:1024px) {
            font-size: 24px;
            margin-bottom: 30px;
            letter-spacing: 1px;
        }
    }

    .description {
        font-size: 35px;
        line-height: 45px;
        letter-spacing: 1px;
        display: flex;
        align-items: center;
        margin-top: 30px;
        font-weight: 500;
        text-align: justify;
        

        @media screen and (max-width:1024px) {
            font-size: 19px;
            width: 100%;
            text-align: center;
            line-height: 24px;
        }
    }
}