.contact-screen {
    background-size: cover;
    background-position: center;
    width: 100%;
    min-height: 100vh;
}

.contact-screen .container {
    padding: 80px 60px;
}

.contact-screen .title {
    margin: 40px 40px 0 40px;
    padding: 0;
    font-size: 65px;
    display: flex;
    justify-content: center;
    text-align: center;
    color: black;
    letter-spacing: 1px;
    animation-name: titleFadeIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    position: relative;
    animation-delay: 0.2s;
    opacity: 0;
    transition: all linear 200ms;
    text-shadow: 2px 2px 2px white;
}

@keyframes titleFadeIn {
    0% {
        opacity: 0;
        top: -120px;
    }
    100% {
        opacity: 1;
        top: 0;
    }
}

.contact-screen .contact-information-row {
    display: flex;  
}

.contact-screen .contact-information-row .contact-screen-description {
    width: 40%;
    padding: 0px;
    display: flex;
    align-items: center;
    animation-name: textFadeIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    position: relative;
    animation-delay: 0.2s;
    opacity: 0;
    transition: all linear 200ms;
}

@keyframes textFadeIn {
    0% {
        opacity: 0;
        left: 120px;
    }
    100% {
        opacity: 1;
        left: 0;
    }
}

.contact-screen .contact-information-row .contact-screen-description .headline:hover {
    transition: all 500ms;
    transform: scale(1.1);
    box-shadow: 1px 1px 10px 1px white;

}


.contact-screen .contact-information-row .contact-screen-description .headline {
    font-size: 25px;
    letter-spacing: 2px;
    margin-left: 60px;
    margin-top: 160px;
    padding: 30px;
    display: flex;
    justify-content: center;
    text-align: center;
    color: black;
    background-color: rgb(220 220 220 / 90%);
    box-shadow: 1px 1px 5px 1px #a5a5a5;
    border-radius: 20px;
}



.contact-screen .contact-information-box {
    background-position: center;
    background-size: cover;
    margin: 50px;
    padding: 20px;
    width: 40%;
    min-height: 330px;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    display: flex;
    color: white;
    border-radius: 20px;
    box-shadow: 1px 1px 20px 5px black;
    animation-name: boxFadeIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    position: relative;
    animation-delay: 0.2s;
    opacity: 0;
    transition: all linear 200ms;
}

@keyframes boxFadeIn {
    0% {
        opacity: 0;
        right: 120px;
    }
    100% {
        opacity: 1;
        right: 0;
    }
}

.contact-screen .contact-information-box:hover {
    transition: all 0.8s;
    transform: scale(1.05);
    cursor: pointer;
    box-shadow: 1px 1px 40px 5px black;

}

.contact-screen .contact-information-box p {
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 1px;
    text-shadow: 1px 1px 30px 1px white;
    color: white;
    font-weight: 400;
    margin: 20px;
    transition: all linear 200ms;
}

.contact-screen .contact-information-box .headline {
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 2px;
    transition: all linear 100ms;
    color: #ffe290;
}

.contact-screen .contact-information-row .contact-person {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
}

.contact-screen .contact-information-row .contact-image {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    object-fit: cover;
}

.contact-screen .contact-information-box .headline:hover {
    transform: scale(1.08);
}


.contact-screen .contact-information-box a {
    color: black;
}

.contact-screen .contact-information-box a:hover {
    color: white;
}


.contact-screen .contact-information-box p:hover {
    color: black;
}

@media screen and (max-width:1024px) {
    
    .contact-screen .container {
        padding: 80px 29px;
    }

    .contact-screen .title {
        margin: 40px;
    }

    .contact-screen .contact-information-row {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .contact-screen .contact-information-box {
        margin:0 0 30px 0;
        width: auto;
        min-height: 0;
        padding: 18px;
    }

    .contact-screen .contact-information-box .headline {
        font-size: 35px;
        letter-spacing: 1px;
        margin: 11px 0 20px 0;
    }

    .contact-screen .contact-information-box p {
        margin: 15px 0;
        font-size: 20px;
        font-weight: 600;
    }

    .contact-screen .contact-information-row .contact-screen-description {
        width: 100%;
    }

    .contact-screen .contact-information-row .contact-screen-description .headline {
        margin: 0;
        font-size: 22px;
    }

}