.full-width-animated {
    min-height: 100vh;
    padding: 80px 0;
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgb(224, 169, 17) 100%);
    text-align: center;
    color: rgba(42,62,82);

    .name {
        font-size: 62px;
        letter-spacing: 2px;
        color: white;
        opacity: 0;
        animation-name: nameSlideIn;
        animation-duration: 500ms;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;

        @media screen and (max-width:1024px) {
            font-size: 36px;
        }
    }

    .age {
        font-size: 50px;
        letter-spacing: 2px;
        color: rgb(37, 54, 70) ;
        opacity: 0;
        animation-name: ageSlideIn;
        animation-duration: 500ms;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;

        @media screen and (max-width:1024px) {
            font-size: 30px;
        }
    }

    .languages {
        font-size: 40px;
        letter-spacing: 2px;

        @media screen and (max-width:1024px) {
            font-size: 20px;
        }
    }

    .languages,
    .hobbies {
        opacity: 0;
        animation-name: itemsSlideUp;
        animation-duration: 500ms;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;

        @media screen and (max-width:1024px) {
            font-size: 20px;
        }
    }

    .image {
        width: 300px;
        height: 300px;
        letter-spacing: 1px;
        border-radius: 50%;
        object-fit: cover;
        opacity: 0;
        animation-name: imageSlideIn;
        animation-duration: 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        margin: auto;

        @media screen and (max-width:1024px) {
            width: 250px;
            height: 250px;

        }
    }
}

@keyframes nameSlideIn {
    0% {
        opacity: 0;
        transform: translateY(-500px);
    }
    25% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes ageSlideIn {
    0% {
        opacity: 0;
        transform: translateX(-500px);
    }
    
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes imageSlideIn {
    0% {
        opacity: 0;
        transform: translateX(500px) translateY(-150px) ;
    }

    50% {
        transform: translateX(0) translateY(-50px) ;
    }
    
    100% {
        opacity: 1;
        transform: translateX(0) translateY(0);
    }
}

@keyframes itemsSlideUp {
    0% {
        opacity: 0;
        transform: translateY(500px);
    }
    
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
