.skills {
    justify-content: center;
    align-items: center;
    background-position: center;
    background-size: cover;
    min-height: 100vh;
    flex-direction: column;
    position: relative;
    padding: 40px;
    animation-name: skillsFadeIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    opacity: 0;
}

.skills * {
    z-index: 3;
}

.skills::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5) ;
    z-index: 1;
}

.skills .headline {
    font-size: 50px;
    margin: 50px 0;
    letter-spacing: 2px;
    color: white;
    border-bottom: 3px solid rgb(234, 0, 0);
    text-align: center;
    position: relative;
    padding: 20px;
}

.skills .skill-list {
    width: 600px;
    margin: auto;
}

.skills .skill-list .skill-row {
    display: flex;
    margin-top:50px ;
    margin-bottom:50px ;
    
}

@keyframes skillsFadeIn {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.skills .skill-list .skill-row img {
    height: 100px;
    width: 100px;
}

.skills .skill-list .skill-row .skill-name {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    font-weight: bold;
    color: white;
    letter-spacing: 1px;
    width: 40%;
}

.skills .skill-rating-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 125px;
}

@media screen and (max-width:1024px) {
    .skills .headline {
        font-size: 36px;
        margin: 0;
        padding-bottom: 20px;
    }

    .skills .skill-list {
        width: auto;
        margin: auto;

    }

    .skills .skill-list .skill-row {
        display: flex;
        margin-top:50px ;
        margin-bottom:50px ;
        flex-direction: column;
        align-items: center;
    }
}