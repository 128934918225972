.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button {
  font-weight: bold;
  border-radius: 25px;
  justify-content: center;
  padding: 12px 40px;
  display: flex;
  font-size: 18px;
  background-color: red;
  color: white;
  border: none ;
  letter-spacing: 1px;
}

.button:hover {
  background-color: rgb(3, 188, 216);
  box-shadow: 1px 1px 15px 1px aqua;
  rotate: -2deg;
  transform: scale(1.1);
  transition: all 300ms;
  color: white;
  cursor: pointer;
}

.button {
  background: linear-gradient(to top, red, #fe7b7b);
  border: none;
  border-radius: 20px;
  color: white;
  padding: 12px 30px;
  font-size: 17px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.button:hover {
  background: linear-gradient(to top, #fe7b7b, red);
}