.content-circles {
    padding:0 0 80px;
    min-height: 60vh;
    background: linear-gradient(0deg, rgb(254, 196, 36) 0%, rgba(255,255,255,1) 80%);


    .container {
        padding: 0 80px;
        display: flex;
        gap: 50px;
        justify-content: center;

        @media screen and (max-width:1024px) {
            flex-direction: column;
            padding: 0 16px;
        }
    }
    .feature {
        flex: 1 1 33.3%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        text-align: center;
        align-items: center;
        padding: 30px;
        transition: all linear 200ms;
        cursor: pointer;
        border-radius: 20px;
        opacity: 0;
        animation-name: cardsFadeIn;
        animation-duration: 500ms;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;

        @keyframes cardsFadeIn {
            0% {
                transform: translateY(500px);
                opacity: 0;
            }
            100% {
                transform: translateY(0);
                opacity: 1;
            }
        }

        &:hover {
            transform: scale(1.2);
            box-shadow: 1px 1px 20px 1px  #363636;
            background: white;

        }

        &:hover .image {
            transform: scale(0.9);
        }

        &:hover .description {
            transform: scale(1.1);
        }

        &:hover .headline{
            transform: scale(1.1);
        }

        @media screen and (max-width:1024px) {
            width: auto;
        }
    }

    .image {
        object-fit: contain;
        max-width: 150px;
        max-height: 150px;
        border-radius: 0;
        margin-bottom: 20px;
        transition: all linear 200ms;
    }
    .headline {
        font-size: 25px;
        letter-spacing: 1px;
        margin: 15px 0;
        transition: all linear 200ms;

    }
    .description {
        font-size: 20px;
        margin-top: 0;
        letter-spacing: 1px;
        margin-bottom: 10px;
        min-height: 100px;
        transition: all linear 200ms;

    }
    .button{
        font-size: 15px;
        margin: 0;

        &:hover {
            box-shadow: 1px 1px 5px 1px rgb(82, 82, 82);
            rotate: none;
            transform: scale(1.1);
        }
    }
    
    a {
        text-decoration: none;
    }
}