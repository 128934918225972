.screen-aboutus {
    min-height: 80vh;
    background-size: cover;
    background-position: center;
    position: relative;

    &::before {
        content: '';
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        background: rgba(rgb(255, 255, 255), .6);
        transition: all linear 200ms;
    }

    * {
        position: relative;
        z-index: 200;
    }

    .container {
        padding: 150px 120px;
        margin: auto;
        max-width: 1200px;

        @media screen and (max-width: 1024px) {
            padding: 120px 50px;
        }
    }

    .title {
        font-size: 90px;
        margin: 0;
        letter-spacing: 1px;
        animation-name: titleSlideIn;
        animation-duration: 800ms;
        animation-fill-mode: forwards;
        opacity: 0;

        @media screen and (max-width: 1024px) {
            font-size: 40px;
        }
    }

    @keyframes titleSlideIn {
        0% {
            opacity: 0;
            transform: translateX(-500px);
        }
        100% {
            opacity: 1;
            transform: translateX(0)
        }
    }

    .description {
        font-size: 32px;
        line-height: 50px;
        text-align: justify;
        animation-name: descriptionSlideIn;
        animation-duration: 800ms;
        animation-fill-mode: forwards;
        animation-delay: 0.5s;
        opacity: 0;

        @media screen and (max-width: 1024px) {
           font-size: 17px;
           line-height: 25px;
           font-weight: 600;
           letter-spacing: 1px;
        }
    }

    @keyframes descriptionSlideIn {
        0% {
            opacity: 0;
            top:500px;
        }
        100% {
            opacity: 1;
            top: 0;
        }
    }
}
